<template>
  <div class="vacancy-box">
      <img class="vacancy-icon" src="@/assets/images/realTimePositioning/zwsj.png" alt="" srcset="" />
      <div>暂无数据</div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.vacancy-box {
  height: 200px;
  img {
    width: 120px;
    height: 94px;
    display: block;
    margin: auto;
  }
  div {
    font-size: 15px;
    color: #333333;
    text-align: center;
    margin-top: 6px;
  }
}
</style>