<template>
  <div class="form-target">
    <template v-if="!readOnly">
      <el-form-item
        prop="yearTargetMoney"
      >
        <!-- :prop="'sss.' + tabInd + '.yearTargetMoney'"
        :rules="[
          { required: true, message: '请输入目标年度保费', trigger: 'blur' },
        ]" -->
        <template slot="label"><span style="color:red" v-if="targetData.year == '2024'">*</span> 目标年度保费(元)：</template>
        <el-input
          v-model="targetData.yearTargetMoney"
          @input="input_num($event, targetData, 'yearTargetMoney', 999999999999999)"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="目标年度赔付率："
        prop="yearTargetCompensate" v-if="!compatibleReadOnly"
      >
        <template slot="label"><span style="color:red" v-if="targetData.year == '2024'">*</span> 目标年度赔付率：</template>
        <el-input
          v-model="targetData.yearTargetCompensate"
          @input="input_num($event, targetData, 'yearTargetCompensate', 100)"
          ><template slot="append">%</template></el-input
        >
      </el-form-item>
      <el-form-item v-if="!compatibleReadOnly"
        label="目标年度客户增长数：" prop="yearTargetIncrease"
      >
        <template slot="label"><span style="color:red" v-if="targetData.year == '2024'">*</span> 目标年度客户增长数：</template>
        <el-input
          v-model="targetData.yearTargetIncrease"
          @input="
            input_num($event, targetData, 'yearTargetIncrease', 999999999999999, 0)
          "
        ></el-input>
      </el-form-item>
    </template>
    <template v-else-if="compatibleReadOnly">
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          目标年度保费(元)：
          <span>{{ targetData.yearTargetMoney || '--' }}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          保单数量：
          <span>{{ targetData.policyCount  || '--'}}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          实际年度保费(元)：
          <span>{{ targetData.yearIncomeMoney  || '--'}}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          案件数：
          <span>{{ targetData.caseCount  || '--'}}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          赔付金额：
          <span>{{ targetData.paidAmount  || '--'}}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          实际年度客户增长数：
          <span>{{ targetData.yearTargetIncrease  || '--' }}</span>
          <!-- yearIncrease -->
        </div>
      </el-col>
    </template>
    <el-row :gutter="10" v-else>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          目标年度保费(元)：
          <span>{{ targetData.yearTargetMoney || '--' }}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          目标年度赔付率：
          <span>{{ targetData.yearTargetCompensate || '--' }}%</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          目标年度客户增长数：
          <span>{{ targetData.yearTargetIncrease  || '--'}}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          实际年度保费(元)：
          <span>{{ targetData.yearIncomeMoney  || '--'}}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          实际年度赔付率：
          <span>{{ targetData.yearIncomeCompensate  || '--'}}%</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
        <div class="detailData-text">
          实际年度客户增长数：
          <!-- <span>{{ targetData.yearIncrease  || '--' }}</span> -->
          <span>{{ targetData.yearTargetIncrease  || '--' }}</span>
        </div>
      </el-col>
    </el-row>
    <el-table :data="targetData.targetMthInfoList" border style="width: 100%">
      <el-table-column prop="date" label="ID" width="52">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="月份信息" width="136">
        <template slot-scope="scope">
          {{ scope.row.month + '月' }}
        </template>
      </el-table-column>
      <el-table-column prop="monthTargetMoney" label="保费目标" width="156">
        <template slot-scope="scope">
          <el-input
            placeholder="请输入"
            v-if="!readOnly"
            v-model="targetData.targetMthInfoList[scope.$index].monthTargetMoney"
            @input="
              input_num(
                $event,
                targetData.targetMthInfoList[scope.$index],
                'monthTargetMoney',
                999999999999999
              )
            "
          ></el-input>
          <span v-else>{{ scope.row.monthTargetMoney || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="policyCount" label="保单数量" width="156" v-if="!children">
        <template slot-scope="scope">
          <el-input
            placeholder="请输入"
            v-if="!readOnly"
            v-model="targetData.targetMthInfoList[scope.$index].policyCount"
            @input="
              input_num(
                $event,
                targetData.targetMthInfoList[scope.$index],
                'monthTargetMoney',
                999999999999999
              )
            "
          ></el-input>
          <span v-else>{{ scope.row.policyCount || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="monthMoney"
        label="实际保费收入"
        v-if="readOnly"
        width="156"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.monthMoney || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="caseCount"
        label="案件数"
        v-if="compatibleReadOnly && readOnly"
        width="156"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.caseCount || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="paidAmount"
        label="赔付金额"
        v-if="compatibleReadOnly && readOnly"
        width="156"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.paidAmount || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="monthTargetCompensate" label="赔付率目标(%)" width="156" v-if="!compatibleReadOnly">
        <template slot-scope="scope">
          <el-input
            placeholder="请输入"
            v-if="!readOnly"
            v-model="targetData.targetMthInfoList[scope.$index].monthTargetCompensate"
            @input="
              input_num(
                $event,
                targetData.targetMthInfoList[scope.$index],
                'monthTargetCompensate',
                100
              )
            "
            ><template slot="append">%</template></el-input
          >
          <span v-else>{{ scope.row.monthTargetCompensate || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="monthCompensate"
        label="实际赔付率(%)"
        v-if="readOnly && !compatibleReadOnly"
        width="156"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.monthCompensate || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="monthTargetIncrease" label="客户数目标(个)" v-if="!compatibleReadOnly" width="156">
        <template slot-scope="scope">
          <el-input
            placeholder="请输入"
            v-if="!readOnly"
            v-model="targetData.targetMthInfoList[scope.$index].monthTargetIncrease"
            @input="
              input_num(
                $event,
                targetData.targetMthInfoList[scope.$index],
                'monthTargetIncrease',
                999999999999999,
                0
              )
            "
          ></el-input>
          <span v-else>{{ scope.row.monthTargetIncrease || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="monthIncrease"
        label="实际客户数"
        v-if="readOnly"
        width="156"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.monthIncrease || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="备注">
        <template slot-scope="scope">
          <el-input
            placeholder="请输入"
            v-if="!readOnly"
            v-model="targetData.targetMthInfoList[scope.$index].remark"
          ></el-input>
          <span v-else>{{ scope.row.remark || "--" }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
export default {
  model: {
    prop: "targetData",
    event: "change",
  },
  props: {
    targetData: {
      type: Object,
      default: () => {
        return {
          year: "2024",
          yearTargetMoney: "",
          yearTargetCompensate: "",
          yearTargetIncrease: "",
          targetMthInfoList: [],
        };
      },
    },
    tabInd: {
      type: [String, Number],
      default: 1,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    compatibleReadOnly: {
      type: Boolean,
      default: false,
    },
    children: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // targetData: {
    //   handler(v) {
    //     console.log(v);
    //   },
    //   deep: true,
    //   immediate: true
    // },
    // fromData: {
    //   handler(v) {
    //     // this.$emit('change', JSON.parse(JSON.stringify(v)));
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      fromData: {
        targetMthInfoList: [],
          yearTargetMoney: "",
          yearTargetCompensate: "",
          yearTargetIncrease: "",
      },
      tableData: [
        {
          acceptRate: "",
        },
      ],
    };
  },
  methods: {
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-target {
}
.detailData-text {
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
}
</style>