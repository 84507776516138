<template>
  <el-dialog :visible.sync="dialogVisible"
    @close="clearClose"
    custom-class="add-risk-dialog"
    destroy-on-close title="风控详情"
    width="616px">
    <el-form :model="currentRow"
      ref="form"
      label-position="right"
      label-width="150px"
      size="mini">
    <div style="heigth: 20px">
    </div>
      <el-form-item label="风控类型："
        prop="riskType">
              {{ currentRow.riskType || '-' }}
      </el-form-item>
      <el-form-item label="风控时间："
        prop="riskDateTime">
        {{ currentRow.riskDateTime || '-' }}
      </el-form-item>
      <el-form-item label="风控地点："
        prop="riskLocations">
              {{ currentRow.riskLocations || '-' }}
      </el-form-item>
      <el-form-item label="详细地址："
        prop="address">
       {{ currentRow.address || '-' }}
      </el-form-item>
      <el-form-item label="风控客户："
        prop="participation">
             {{ currentRow.participation || '-' }}
      </el-form-item>
      <el-form-item label="费用开销(元)："
        prop="expenditure">
          {{ currentRow.expenditure || '-' }}
      </el-form-item>
      <el-form-item label="参与人员："
        prop="participationNumber">
        {{ currentRow.participationNumber || '-' }}
      </el-form-item>
      <el-form-item label="风控人员："
        prop="riskPersonnel">
         {{ currentRow.riskPersonnel || '-' }}
      </el-form-item>
      <el-form-item label="风控单据："
        prop="charge">
        <div style="display:flex;flex-wrap: wrap">
          <img v-for="item,index in (currentRow.projectRiskFileList||[])" :key="index" style="margin-right:10px;margin-bottom:10px;border: 1px solid #ccc;" :src="item.attUrl" class="avatar">
        </div>
      </el-form-item>
      <el-form-item label="备注："
        prop="remark">
        {{ currentRow.remark || '-' }}
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import VDistpicker from "v-distpicker";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import { OSSDirectPass } from "@/api/oss.js";
export default {
  name: "AddRiskDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      default: ()=>{}
    }
  },
  components: { VDistpicker, EmployeeSelect },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      CXchannel: [],
      topLevelTips: "没有上级部门！",
      parentOption: [{ id: null, label: null }],
      form: {
        parentId: "",
        deptName: "",
        charge: "",
        remark: "",
      },
      rules: {
        parentId: [
          { required: true, message: "请选择上级部门", trigger: "change" },
        ],
        deptName: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
          { required: true, message: "请输入部门名称", trigger: "change" },
          {
            min: 0,
            max: 24,
            message: "长度在 0 到 24 个字符",
            trigger: "change",
          },
        ],
        charge: [
          { required: true, message: "请输入部门负责人", trigger: "blur" },
          { required: true, message: "请输入部门负责人", trigger: "change" },
          {
            min: 0,
            max: 24,
            message: "长度在 0 到 24 个字符",
            trigger: "change",
          },
        ],
      },
    };
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  mounted() { },
  methods: {
    reset() {
      this.parentOption = [{ id: null, label: null }];
      if (this.inputData && this.actionType == "edit") {
        this.parentOption.push({
          id: this.inputData.parentId,
          label:
            this.inputData.parentId == this.topLevelId
              ? this.topLevelTips
              : this.inputData.parentName,
        });
        this.form = Object.assign({}, this.inputData);
      } else {
        this.clearClose();
      }
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let emitData = this.form;
          if (this.actionType == "edit") {
            emitData = {
              ...this.form,
              id: this.inputData.id,
            };
          }
          this.$emit("confirm", emitData);
          this.visible = false;
        }
      });
    },
    clearClose() {
      this.parentOption = [{ id: null, label: null }];
      //this.$refs.form.resetFields();
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
    onSelected(e) {
      this.form.dangerProvince = e.province.value;
      this.form.dangerCity = e.city.value;
      this.form.dangerCounty = e.area.value;
      // this.addressValue = e.province.value + e.city.value + e.area.value + this.ruleForm.dangerDetailAddress;
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    async beforeAvatarUpload(file) {
      this.$refs.uploadaaa.clearFiles()
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        this.$message({
          message: `上传的文件不能大于${ 5 }MB`,
          type: "warning",
        });
        return;
      }
      const url = await OSSDirectPass(file);
      this.form.brandPicture = url;
    },
  },
};
</script>

<style lang="scss">
.add-risk-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .el-form-item {
    width: calc(100% - 80px) !important;
  }
  .avatar {
    width: 130px;
    height: 130px;
    display: block;
  }
}
</style>
