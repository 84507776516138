<template>
  <div class="xmgl-box">
    <div class="xmgl-box-item" v-for="item,index in listData" :key="index">
      <div class="xmgl-box-item-name">{{ item.label }}</div>
      <div class="xmgl-box-item-value">{{ detailsData[item.key] || '--' }}{{ item.units }}</div>
    </div>
  </div>
</template>

<script>
import { projectIdxSummary } from "@/api/basicData";
export default {
  props: {
    // detailsData: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  data() {
    return {
      listData: [
        {
          label: "签约赔付率",
          key: "signCompensateRate",
          units: "%",
        },
        {
          label: "目标赔付率",
          key: "targetCompensate",
          units: "%",
        },
        {
          label: "签约管控率",
          key: "signCtrlRate",
          units: "%",
        },
        {
          label: "目标管控率",
          key: "targetCtrlRate",
          units: "%",
        },
        {
          label: "管控率",
          key: "ctrlRate",
          units: "%",
        },
        {
          label: "满期赔付率",
          key: "completelyCompensateRate",
          units: "%",
        },
        // {
        //   label: "已报告赔付率",
        //   key: "reportedCompensateRate",
        //   units: "%",
        // },
        {
          label: "赔付总金额(元)",
          key: "claimsMoney",
          units: "",
        },
        {
          label: "已出险未报案金额(元)",
          key: "denyAmount",
          units: "",
        },
        {
          label: "未结案件金额(元)",
          key: "estimatedLoss",
          units: "",
        },
        {
          label: "签单总保费(元)",
          key: "signOrderPremiums",
          units: "",
        },
        {
          label: "已赚保费(元)",
          key: "profitPremium",
          units: "",
        },
        {
          label: "保单总数",
          key: "policyCount",
          units: "",
        },
        {
          label: "标的数量",
          key: "subjectCount",
          units: "",
        },
        {
          label: "案件总数",
          key: "caseCount",
          units: "",
        },
        {
          label: "机械设备",
          key: "deviceCount",
          units: "",
        },
      ],
      detailsData: {}
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      projectIdxSummary(this.$route.query.id)
        .then((res) => {
          this.detailsData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.xmgl-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 14px;
  &-item {
    width: 200px;
    height: 74px;
    background: #f2f7fd;
    padding: 13px 0 0 16px;
    border-radius: 6px 6px 6px 6px;
    &-name {
      font-size: 14px;
      color: #333333;
      margin-bottom: 6px;
    }
    &-value {
      font-weight: bold;
      font-size: 18px;
      color: #0080ff;
      line-height: 18px;
    }
  }
}
</style>