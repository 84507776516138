<template>
  <div class="project-target">
    <el-tabs type="border-card">
      <!-- <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 我的行程</span>
        我的行程
      </el-tab-pane> -->
      <el-tab-pane :label="item.year + ''" v-for="item,index in targetList" :key="index">
        <TargetForm :readOnly="readOnly" v-model="targetList[index]" :tabInd="index" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TargetForm from "@/views/routerProjectManagement/components/ProjectList/components/targetForm.vue";
export default {
  model: {
    prop: 'targetList',
    event: 'change'
  },
  props: {
    targetList: {
      type: [Object,Array],
      default: ()=> {}
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabsList: []
    }
  },
  components: {
    TargetForm
  }
}
</script>

<style lang="scss" scoped>
.project-target {
  ::v-deep .el-tabs--border-card {
    border: none;
    box-shadow: none;
    .el-tabs__content {
      border: 1px solid #DEE3E6;
    }
   .el-tabs__header {
      width: initial !important;
      background-color: transparent;
      border-bottom: none;
      margin-bottom: -2px;
      // background-color: #F5F7FA;
      // border-bottom: 1px solid #E4E7ED;
      .el-tabs__item {
        margin-right: 6px;
        border: 1px solid #DEE3E6;
        background: #F2F7FD;
      }
      .is-active {
        border-bottom: none;
        background: #fff;
      }
      .el-tabs__nav {
        border: 1px solid transparent;
      }
    }
  }
} 

</style>