<template>
  <div class="project-statistics-table">
    <div class="project-statistics-table-header">
      <div>
        <el-button
          icon="el-icon-refresh"
          @click="handleRefresh"
          style="color: #4278c9; border: 1px solid #4278c9; margin-right: 12px"
          plain
        ></el-button>
        <el-button
          type="primary"
          v-if="option && option.isAdd"
          @click="isAddRiskDialog = true, editTrainingData= {}"
          >添加培训</el-button
        >
      </div>
      <div class="project-statistics-table-header">
        <el-select v-model="requestData.state" placeholder="请选择" style="margin-right:8px;" v-if="option.showSelect" @change="handleChangeSelect">
          <el-option
            v-for="item in option.selectList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input style="margin-right:8px;" v-if="option.isKeyWord"
          placeholder="请输入关键词" @input="handleInput"
          prefix-icon="el-icon-search"
          v-model="requestData.searchKeyWord">
        </el-input>
        <div
          class="el-button el-button--primary el-button--small project-statistics-table-header"
          style="background: #4278c9; border-color: #4278c9"
          @click="handleExportEmit()"
          v-if="option && option.isExport"
        >
          <img
            src="@/assets/images/policy/Export.png"
            style="width: 16px; height: 16px; margin-top: -2px; margin-right: 5px"
            alt
            srcset
          />
          导出
        </div>
      </div>
    </div>
    <div v-if="option && option.tableList">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="index" label="序号" width="52" fixed>
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          v-for="(item, index) in option.tableList"
          :key="index + 55" :fixed="item.fixed"
          :width="item.width"
        >
          <template slot-scope="scope">
            <slot
              :item="JSON.parse(JSON.stringify(scope.row))"
              :name="item.prop"
              v-if="item.isSlot"
            />
            <div v-else>
              {{
                scope.row[item.prop] === undefined ||
                scope.row[item.prop] === "" ||
                scope.row[item.prop] === null
                  ? option.rowPlaceholder || item.rowPlaceholder || "--"
                  : scope.row[item.prop]
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="requestData.index"
        :page-sizes="[10, 20, 50, 100, 200, 300, 400]"
        :page-size="requestData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <AddRiskDialog v-model="isAddRiskDialog" :editData="editTrainingData" @changeList="handleGetList"  />
  </div>
</template>

<script>
import AddRiskDialog from "@/views/routerProjectManagement/components/ProjectList/components/AddRiskDialog.vue";
export default {
  props: {
    option: {
      type: Object,
      default: () => {},
    },
  },
  components: { AddRiskDialog },
  data() {
    return {
      tableData: [],
      projectData: {},
      requestData: {
        index: 1,
        size: 10,
        searchKeyWord: '',
        state: '',
        likeParam: '',
        terminalStatus: '',
      },
      total: 0,
      timeObj: null,
      isAddRiskDialog: false,
      editTrainingData: null
    };
  },
  methods: {
    handleGetList() {
      this.option
        .api({
          ...this.option.apiData,
          ...this.requestData,
          likeParam: this.requestData.likeParam,
          terminalStatus: this.requestData.state,
          projectId: this.$route.query.id,
        })
        .then(({code,data}) => {
          if (code == 200 && data && data.records && data.records.length) {
            this.tableData = data.records;
            this.total = data.total;
          }else {
            this.tableData = [];
            this.total = 0;
          }
        }).catch(err=>{
          this.tableData = [];
          this.total = 0;
        });
    },
    handleEditData(e) {
      this.editTrainingData = e;
      this.isAddRiskDialog = true;
    },
    handleRefresh() {
      this.handleGetList();
    },
    handleExportEmit() {
      // this.option.apiData.tableIndex
      if (!this.option.exportApi) {
        this.$message.info('正在加急开发中!')
        return false;
      }
      this.option
        .exportApi({
          ...this.requestData,
          projectId: this.$route.query.id,
        })
        .then((res) => {
          console.log(res);
        });
    },
    handleSizeChange(val) {
      this.requestData.index = 1;
      this.requestData.size = val;
      this.handleGetList();
    },
    handleCurrentChange(val) {
      this.requestData.index = val;
      this.handleGetList();
    },
    handleGoPath() {
      this.$router.push({
        path:
          "/routerProjectManagement/addSubProject?parentId=" +
          this.$route.query.id+'&parentName=' +this.projectData.projectName,
      });
    },
    handleInput() {
      clearTimeout(this.timeObj);
      this.timeObj = setTimeout(() => {
        clearTimeout(this.timeObj);
        this.requestData.index = 1;
        this.handleGetList();
      }, 500);
    },
    handleChangeSelect() {
      this.requestData.index = 1;
      this.handleGetList();
    }
  },
};
</script>

<style lang="scss" scoped>
.project-statistics-table {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }
}
</style>