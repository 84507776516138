<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="clearClose" :close-on-click-modal="false"
    custom-class="add-risk-dialog"
    destroy-on-close
    :title="editData && editData.id ? '编辑培训记录' : '添加'"
    width="636px"
  >
    <div slot="footer">
      <el-button @click="clearClose">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="150px" style="max-height: 60vh;overflow: auto;"
      size="mini"
    >
      <el-form-item label="客户名称：" prop="customerId">
        <el-select
          v-model="form.customerId"
          style="width: 100%" @change="handleChangeSelect($event, 'id', 'customerName', participationList, 'account', form)"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in participationList"
            :key="item.id"
            :label="item.account"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="培训类型：" prop="trainingTypeValue" required>
        <el-select
          v-model="form.trainingTypeValue"
          style="width: 100%"
          filterable @change="handleChangeSelect($event, 'dictKey', 'trainingType', riskType, 'dictValue', form)"
          placeholder="请选择"
        >
          <el-option
            v-for="item in riskType"
            :key="item.dictKey"
            :label="item.dictValue"
            :value="item.dictKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="培训日期：" prop="trainingDate">
        <el-date-picker
          v-model="form.trainingDate"
          type="date"
          format="yyyy / MM / dd"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="所在地区：" prop="province">
        <v-distpicker
          class="distpicker-sb-box"
          :province="form.province"
          :city="form.city"
          :area="form.region"
          @selected="onSelected"
        ></v-distpicker>
      </el-form-item>
      <el-form-item label="详细地址：" prop="address">
        <el-input v-model="form.address" clearable @change="handleInput"></el-input>
      </el-form-item>
      <!-- <el-form-item label="风控客户：" prop="participation">
        <el-select
          v-model="form.participation"
          filterable
          style="width: 100%"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in participationList"
            :key="index"
            :label="item.playersName"
            :value="item.playersName"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="参与人数：" prop="participationNumber">
        <el-input
          v-model="form.participationNumber"
          @input="input_num($event, form, 'participationNumber', 99999999999)"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="客户满意度：" prop="trainingSatisfaction" required>
        <el-select
          v-model="form.trainingSatisfaction"
          style="width: 100%"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in satisfactionList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="培训人员：" prop="teacherId">
        <EmployeeSelect isChangeTenantId
                v-model="form.teacherId"
                @change="handleChangeSalesman"
                placeholder="请选择"
              />
      </el-form-item>
      <el-form-item label="费用开销(元)：" prop="expenditure">
        <el-input
          v-model="form.expenditure"
          @input="input_num($event, form, 'expenditure', 99999999999)"
          ><template slot="append">元</template></el-input
        >
      </el-form-item>
      <el-form-item label="培训附件：" prop="attachment">
        <el-upload
          class="avatar-uploader"
          accept=".jpg,.png,.pdf,.doc,.docx,.xlx,.xlxs"
          ref="attachment"
          :auto-upload="false"
          action="#"
          :on-change="
            (file, fileList) => {
              beforeAvatarUpload(
                file,
                'attachment',
                'projectRiskFile'
              );
            }
          "
          list-type="picture-card"
          multiple
          :file-list="form.attachment || []"
          :limit="5"
        >
          <!-- <img v-if="form.projectRiskFileList" :src="form.ProjectRiskFile" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i> --><i
            slot="default"
            class="el-icon-plus"
          ></i>
          <div slot="file" slot-scope="{ file }">
            <FileIcon :fileName="file.attName"  :showDelet="false" :suffix="file.attType" v-if="['pdf','doc','docx','xls','xlsx'].includes(file.attType)" />
            <img v-else
              class="el-upload-list__item-thumbnail"
              :src="file.url || file.attUrl"
              alt=""
            />
            
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="
                  handleRemove(file, 'attachment', 'projectRiskFile')
                "
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <div style="font-size: 14px;color: #333333;">可上传多个文件，支持上传的格式：jpg、png、pdf、doc、docx、xlx、xlxs</div>
      </el-form-item>
      <el-form-item label="培训回顾：" prop="trainingReview">
        <el-upload
          class="avatar-uploader"
          ref="trainingReview"
          :auto-upload="false"
          accept=".jpg,.png,.mp4"
          action="#"
          :on-change="
            (file, fileList) => {
              beforeAvatarUpload(file, 'trainingReview', 'trainingFile');
            }
          "
          list-type="picture-card"
          multiple
          :file-list="form.trainingReview || []"
          :limit="5"
        >
          <!-- <img v-if="form.projectRiskFileList" :src="form.ProjectRiskFile" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i> --><i
            slot="default"
            class="el-icon-plus"
          ></i>
          <div slot="file" slot-scope="{ file }">
            <video :src="file.url || file.attUrl" v-if="file.attType == 'mp4'"></video>
            <img v-else
              class="el-upload-list__item-thumbnail"
              :src="file.url || file.attUrl"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file, 'trainingReview', 'trainingFile')"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <div style="font-size: 14px;color: #333333;">可上传多个文件，支持上传的格式：jpg、png、mp4</div>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          :rows="4"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import VDistpicker from "v-distpicker";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import { OSSDirectPass } from "@/api/oss.js";
import { dictionaryBatch } from "@/api/policy";
import { getProjectDetail } from "@/api/project";
import { saveTrainingRecordPage, projectCustomerPage, trainingRecordDetail } from "@/api/basicData";
import FileIcon from "@/components/DragMultipleUpload/FileIcon.vue";
export default {
  name: "AddRiskDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  components: { VDistpicker, EmployeeSelect, FileIcon },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      riskType: [],
      participationList: [],
      form: {
        customerId: "",
        customerName: '',
        trainingType: '',
        trainingTypeValue: '',
        trainingDate: "",
        province: '',
        city: '',
        region: '',
        address: "",
        fullAddress: '',
        participationNumber: "",
        trainingSatisfaction: '',
        trainingSatisfactionDesc: '',
        expenditure: "",
        remark: '',
        attachment: [],
        trainingReview: [],
        teacherId: '',
        teacherName: '',
      },
      rules: {
        customerId: [
          { required: true, message: "请选择客户", trigger: "change" },
        ],
        trainingTypeValue: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        trainingDate: [
          { required: true, message: "请选择培训日期", trigger: "change" },
        ],
        province: [
          { required: true, message: "请选择所在地点", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ],
        trainingSatisfaction: [
          { required: true, message: "请选择客户满意度", trigger: "change" },
        ],
        // expenditure: [
        //   { required: true, message: "请输入费用开销", trigger: "change" },
        // ],
        // participationNumber: [
        //   { required: true, message: "请输入参与人数", trigger: "change" },
        // ],
        teacherId: [
          { required: true, message: "请选择培训人员", trigger: "change" },
        ],
        // projectRiskFileList: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "请选择风控单据",
        //     trigger: "change",
        //   },
        // ],
      },
      satisfactionList: [
        {
          key: 5,
          value: "非常满意",
        },
        {
          key: 4,
          value: "满意",
        },
        {
          key: 3,
          value: "一般",
        },
        {
          key: 2,
          value: "不满意",
        },
        {
          key: 1,
          value: "非常不满意",
        },
      ],
    };
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.initType();
          if (this.editData && this.editData.id) {
            this.initDetails();
          }
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  mounted() {},
  methods: {
    reset() {
      this.parentOption = [{ id: null, label: null }];
      this.clearClose();
    },
    confirm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          // this.$emit("submit", JSON.parse(JSON.stringify(this.form)));
          this.form.projectId = this.$route.query.id;
          this.form.loginUserId = this.$store.state.userInfo.MJUserId;
          const res = await saveTrainingRecordPage(this.form);
          if (res.code == 200) {
            this.$emit('changeList');
          }
          this.clearClose();
        }
      });
    },
    clearClose() {
      this.dialogVisible = false;
      this.form = {
        customerId: "",
        customerName: '',
        trainingType: '',
        trainingTypeValue: '',
        trainingDate: "",
        province: '',
        city: '',
        region: '',
        address: "",
        fullAddress: '',
        participationNumber: "",
        trainingSatisfaction: '',
        trainingSatisfactionDesc: '',
        expenditure: "",
        remark: '',
        attachment: [],
        trainingReview: [],
        teacherId: '',
        teacherName: '',
      }
    },
    onSelected(e) {
      console.log(e.area.value);
      this.form.province = e.province.value;
      this.form.city = e.city.value;
      this.form.region = e.area.value;
      this.form.fullAddress =
        e.province.value + "-" + e.city.value + "-" + e.area.value + this.form.address;
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    handleRemove(file, t1, t2) {
      let ind = this.form[t1].findIndex((val) => (val.url || val.attUrl) == (file.url  || file.attUrl));
      this.form[t1].splice(ind, 1);
      !this.form[t1] || !this.form[t1].length ? (this.form[t2] = "") : "";
    },
    async beforeAvatarUpload(file, type, type2) {
      this.$refs[type].clearFiles();
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        this.$message({
          message: `上传的文件不能大于${5}MB`,
          type: "warning",
        });
        return;
      }
      const url = await OSSDirectPass(file);
      this.form[type]
        ? this.form[type].push({
            attName: file.name,
            attType: file.name.split(".")[1],
            attSize: fileSize + "M",
            url,
            attUrl: url,
          })
        : (this.form[type] = [
            {
              attName: file.name,
              attType: file.name.split(".")[1],
              attSize: fileSize + "M",
              url,
              attUrl: url,
            },
          ]);
      this.$forceUpdate();
    },
    initType() {
      dictionaryBatch({
        codes: "riskCtrlTrainingType",
      }).then((res) => {
        if (res && res.data) {
          const { riskCtrlTrainingType } = res.data;
          this.riskType = riskCtrlTrainingType || [];
        }
      });
      projectCustomerPage({
        projectId: this.$route.query.id,
        index: 1,
        size: 9999,
      })
        .then((res) => {
          res.data &&
          res.data.records &&
          res.data.records.length
            ? (this.participationList = res.data.records)
            : (this.participationList = []);
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async initDetails() {
      try {
        let {code, data} = await trainingRecordDetail(this.editData.id);
        if (code == 200) {
          this.form = {...this.form, ...data};
        }
      } catch (error) {
        
      }
    },
    handleChangeSalesman(e, name) {
      this.form.teacherName = name;
      this.$refs.form.validateField("teacherId");
    },
    handleChangeSelect(e, checkKey, key, itemList, itemKey, receivingObj) {
      let findData = itemList.find(val=> val[checkKey] == e);
      receivingObj[key] = findData[itemKey];
      console.log(this.form.customerName);
    },
    handleInput(e) {
      this.form.fullAddress =
        this.form.province ? (this.form.province + "-" + this.form.city + "-" + this.form.region) : '' + this.form.address;
    }
  },
};
</script>

<style lang="scss">
.add-risk-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .el-form-item {
    width: calc(100% - 80px) !important;
  }
  .avatar-uploader {
    ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      width: 130px;
      height: 130px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 130px;
    height: 130px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height: 130px;
    text-align: center;
  }
  .avatar {
    width: 130px;
    height: 130px;
    display: block;
  }
  .distpicker-sb-box {
      select {
        padding: 0;
        height: 32px;
        width: 32%;
        font-size: 14px;
        color: #333333;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DEE3E6;
      }
  }
}
</style>
