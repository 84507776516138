<template>
  <div class="indicators">
    <el-tabs type="border-card">
      <el-tab-pane label="销售统计">
        <TargetForm  :readOnly="true" v-model="detailData" :compatibleReadOnly="true" />
      </el-tab-pane>
      <el-tab-pane label="数据分析">
        <vacancy />
      </el-tab-pane>
    </el-tabs>
    <el-select v-model="yearValue" class="indicators-year" placeholder="请选择">
      <el-option
        label="2024年"
        value="2024">
      </el-option>
      <el-option
        label="2023年"
        value="2023">
      </el-option>
      <el-option
        label="2022年"
        value="2022">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import TargetForm from "@/views/routerProjectManagement/components/ProjectList/components/targetForm.vue";
import vacancy from "@/views/routerProjectManagement/components/ProjectList/components/vacancy.vue";
import { projectTargetInfo } from "@/api/basicData";
export default {
  props: {
    // detailData: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  components: {
    TargetForm,
    vacancy
  },
  watch: {
    yearValue: {
      handler(v) {
        this.getDetail();
      },
      immediate: true
    }
  },
  data() {
    return {
      indicators: [
        {
          label: "签约保费：",
          key: "signPremiums",
          units: "元",
        },
        {
          label: "签约赔付率：",
          key: "signCompensateRate",
          units: "%",
        },
        {
          label: "目标赔付率：",
          key: "targetCompensate",
          units: "%",
        },
        {
          label: "签约管控率：",
          key: "signCtrlRate",
          units: "%",
        },
        {
          label: "目标管控率：",
          key: "targetCtrlRate",
          units: "%",
        },
      ],
      yearValue: '2024',
      detailData: {}
    };
  },
  methods: {
    getDetail() {
      projectTargetInfo({id: this.$route.query.id, year: this.yearValue})
        .then((res) => {
          this.detailData = res.data.find(val=> val.year == this.yearValue);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.indicators {
  position: relative;
  padding-top: 10px;
  &-year {
    position: absolute;
    right: 0px;
    top: 10px;
    ::v-deep .el-input__inner {
      width: 100px;
      background-color: #F2F7FD;
      border: 1px solid #DEE3E6;
    }
  }
  ::v-deep .el-tabs--border-card {
    border: none;
    box-shadow: none;
    .el-tabs__content {
      border: 1px solid #DEE3E6;
    }
   .el-tabs__header {
      width: initial !important;
      background-color: transparent;
      border-bottom: none;
      margin-bottom: -2px;
      // background-color: #F5F7FA;
      // border-bottom: 1px solid #E4E7ED;
      .el-tabs__item {
        margin-right: 6px;
        border: 1px solid #DEE3E6;
        background: #F2F7FD;
        border-radius: 6px 6px 0 0;
      }
      .is-active {
        border-bottom: none;
        background: #fff;
      }
      .el-tabs__nav {
        border: 1px solid transparent;
      }
    }
  }
}
.detailData-text {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}
</style>